import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
  animations: [egretAnimations]
})
export class BlogComponent extends BaseService implements OnInit {
  courses:any[] = [];
  viewMode: string = 'grid-view';//layout config

  constructor(injector:Injector) { super(injector); }
  
  ngOnInit(): void {
    this.seoService.init(this.seoService.getAboutMetaTags());
    this.setExperience();
    this.getHomeInformation();
  }

  getHomeInformation():void {
    this.service.getHomeInformation().subscribe(
      (response:any) => {
        const body:IResponse = response.body;
        if (!body.success) {
          this.service.openDialog(body.message);
        }
        this.courses = body.data.courses;
        console.log(this.courses,body);
      },
      (error:HttpErrorResponse) => {
        this.service.processHttpResponse(error);
      }
    );
  }
  
  yearsExperience:number = 22;
  setExperience():void {
    let date:Date = new Date();
    let currentYear:number = date.getFullYear();
    let founded:number = 1999;
    this.yearsExperience = currentYear - founded;
  }
}
